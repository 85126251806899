var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "introduction"
  }, [_c('div', {
    staticClass: "introduction__line-area"
  }, [_c('v-row', {
    staticClass: "my-0 row--lg h-100"
  }, [_c('v-col', {
    staticClass: "py-0 h-100",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "introduction__line introduction__line--1"
  }, [_c('p', {
    staticClass: "introduction__line__caption font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase"
  }, [_vm._v("Lawfirm Taesung")])])]), _c('v-col', {
    staticClass: "py-0 h-100",
    attrs: {
      "offset-md": "4",
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "introduction__line introduction__line--2"
  }, [_c('p', {
    staticClass: "introduction__line__caption font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase"
  }, [_vm._v("Lawfirm Taesung")])])])], 1)], 1), _c('div', {
    staticClass: "introduction__title mb-20px mb-md-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-primary', {
    staticClass: "pb-md-0",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "TitAdd",
      fn: function () {
        return [_vm._v("Introduction")];
      },
      proxy: true
    }])
  }, [_vm._v(" 변호사 소개 ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "introduction-contents__btn-group",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn swiper-btn-prev",
    attrs: {
      "prev": "",
      "color": "grey lighten-1"
    }
  })], 1), _c('v-col', {
    staticClass: "d-md-none",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "line"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-arrow-primary', {
    staticClass: "swiper-btn swiper-btn-next",
    attrs: {
      "next": "",
      "color": "grey lighten-1"
    }
  })], 1)], 1)], 1)])], 1)], 1), _c('div', {
    staticClass: "introduction__slide",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('swiper', {
    ref: "swiperIntroduction",
    staticClass: "swiper swiper--introduction",
    attrs: {
      "options": _vm.swiperOptionIntroduction
    }
  }, _vm._l(_vm.slide, function (item, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('div', {
      staticClass: "introduction-item"
    }, [_c('div', {
      staticClass: "introduction-item__image"
    }, [_c('div', {
      staticClass: "introduction-item__image__inner image-card",
      style: 'background-image: url(/images/main/lawyer/' + item.title + '.jpg)'
    }), _c('div', {
      staticClass: "introduction-item__image__contents pa-20px"
    }, [_c('txt', {
      staticClass: "white--text"
    }, [_c('v-row', {
      staticClass: "row--xxs text-center",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('p', {
      staticClass: "introduction-item__image__text introduction-item__image__text--3 font-weight-bold line-height-1 font-secondary"
    }, [_vm._v(_vm._s(item.titleEn))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('h4', {
      staticClass: "introduction-item__image__text introduction-item__image__text--1 txt--xl"
    }, [_vm._v(_vm._s(item.title))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "introduction-item__image__text introduction-item__image__text--2"
    }, [item.ceo ? _c('span', [_vm._v("대표 변호사")]) : _c('span', [_vm._v("변호사")])])])], 1)], 1)], 1)]), _c('div', {
      staticClass: "introduction-item__contents pt-16px pt-md-32px"
    }, [_c('txt', {
      staticClass: "white--text"
    }, [_c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('p', {
      staticClass: "font-weight-bold line-height-1 txt--light font-secondary"
    }, [_vm._v(_vm._s(item.titleEn))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('h4', {
      staticClass: "txt--xl"
    }, [_vm._v(_vm._s(item.title))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [item.ceo ? _c('span', [_vm._v("대표 변호사")]) : _c('span', [_vm._v("변호사")])])], 1)], 1)], 1)])]);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }