var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.gate, function (item, index) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "main-gate v-card--none-active-bg",
      style: 'background-image: url(' + item.image + ')',
      attrs: {
        "href": item.link,
        "target": "_blank",
        "tile": ""
      }
    }, [_c('div', {
      staticClass: "main-gate__inner py-50px px-20px px-md-30px pa-lg-60px"
    }, [_c('div', {
      staticClass: "main-gate__wrap"
    }, [_c('div', {
      staticClass: "text-center",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 200 * index
      }
    }, [_c('v-img', {
      staticClass: "mb-20px mb-md-40px mx-auto",
      attrs: {
        "src": item.icon,
        "max-width": "80",
        "aspect-ratio": 1 / 1
      }
    }), _c('tit', {
      staticClass: "font-size-xl-56 white--text"
    }, [_vm._v(_vm._s(item.title))]), _c('txt', {
      staticClass: "txt--lg font-size-xl-28 grey--text text--lighten-3 line-height-17 mt-18px mt-md-24px"
    }, _vm._l(item.info, function (detail) {
      return _c('p', {
        key: detail,
        staticClass: "mt-0"
      }, [_vm._v(_vm._s(detail))]);
    }), 0), _c('div', {
      staticClass: "btn-wrap btn-wrap--lg"
    }, [_c('v-btn', {
      staticClass: "main-gate__btn v-size--xx-large"
    }, [_vm._v(" 바로가기 "), _c('icon-arrow-primary', {
      staticClass: "icon--size-large ml-6px ml-lg-10px"
    })], 1)], 1)], 1)]), _c('p', {
      staticClass: "main-gate__caption mt-30px mt-md-0"
    }, [_vm._v("Lawfirm Taesung")])])])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }