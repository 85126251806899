<template>
    <div class="main-visual">
        <div class="main-visual__bg"></div>
        <v-sheet color="transparent" class="main-visual__inner text-center pt-80px pt-xl-130px tertiary--text line-height-1">
            <tit data-aos="fade-up" class="tit--lg">집의 안팎을 살핍니다</tit>
            <div data-aos="fade-up" data-aos-delay="200" class="font-size-2 font-size-md-28 font-weight-medium text-uppercase mt-20px mt-md-30px">caring for you both inside and outside your home</div>
            <div data-aos="fade-up" data-aos-delay="300" class="font-size-2 font-size-md-28 mt-10px mt-md-16px">아파트 하자 · 단체등기 · 재개발 재건축 · 이혼</div>
            <txt data-aos="fade-up" data-aos-delay="400" class="txt--lg txt--dark mt-20px mt-md-30px">법무법인 태성은 건설과 이혼 분야에 특화된 로펌입니다.</txt>
        </v-sheet>
        <!-- <v-row no-gutters class="main-visual__inner">
            <v-col cols="12" md="4" class="main-visual__contents main-visual__contents--1">
                <v-img data-aos="fade-right" data-aos-delay="1000" src="/images/main/visual-img.svg" max-height="100%" contain :aspect-ratio="612 / 532" class="main-visual__contents--1__img mw-50 mw-md-100 w-100" />
            </v-col>
            <v-col cols="12" md="4" class="main-visual__contents main-visual__contents--2">
                <v-img data-aos="fade-down" data-aos-delay="1200" src="/images/main/visual-img2.svg" :aspect-ratio="976 / 148" contain class="d-none d-md-block h-100" />
                <div class="d-md-none px-16px w-100">
                    <v-img data-aos="fade-down" data-aos-delay="1200" src="/images/main/visual-img2-mo.svg" :aspect-ratio="148 / 976" contain class="w-100" />
                </div>
            </v-col>
            <v-col cols="12" md="4" class="main-visual__contents main-visual__contents--3">
                <div class="main-visual__contents--3__inner pr-36px pl-12px pr-md-12px pl-md-8px">
                    <p data-aos="fade-up" data-aos-delay="1800" class="main-visual__caption font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase" >Lawfirm Taesung</p>
                    <div class="text-right text-md-left">
                        <tit data-aos="fade-left" data-aos-delay="1400" class="tit--lg white--text font-weight-regular">집의 안팎을</tit>
                        <tit data-aos="fade-left" data-aos-delay="1600" class="tit--lg white--text">살핍니다</tit>
                    </div>
                </div>
            </v-col>
        </v-row> -->
        <u-scroll-ani />
    </div>
</template>

<script>
import UScrollAni from "@/components/publish/styles/scroll/u-scroll-ani.vue";
import TitWrap from "@/components/publish/styles/typography/tit-wrap.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        UScrollAni,
        TitWrap,
        Tit,
        Txt,
    },
    props: {},
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 0,
                    duration: 1000,
                    throttleDelay: 100,
                    easing: "ease-out",
                    anchorPlacement: "top-center",
                });
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    &__bg {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/images/main/visual.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__inner {
        height: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        .main-visual__contents:not(:last-child) {
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.2);
                right: 0;
                bottom: 0;
                transform: scaleX(0);
                opacity: 0;
                visibility: hidden;
                animation: scaleX 1.5s 0.1s ease-out forwards;
            }
        }
        .main-visual__contents:nth-child(1) {
            &::after {
                transform-origin: left center;
            }
        }
        .main-visual__contents:nth-child(2) {
            &::after {
                transform-origin: right center;
            }
        }
    }
    &__contents {
        height: calc(100% / 3);
        position: relative;
        display: flex;
        align-items: center;
        &--1 {
            &__img {
                margin-top: auto;
                ::v-deep {
                    .v-image__image {
                        background-position: left bottom !important;
                    }
                }
            }
        }
        &--3 {
            &__inner {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                .main-visual__caption {
                    position: absolute;
                    right: 16px;
                    bottom: 16px;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    // main-visual
    .main-visual {
        &__inner {
            height: 100%;
            .main-visual__contents:not(:last-child) {
                &::after {
                    width: 1px;
                    height: 100%;
                    transform: scaleY(0);
                    animation: scaleY 1.5s 0.1s ease-out forwards;
                }
            }
            .main-visual__contents:nth-child(1) {
                &::after {
                    transform-origin: center top;
                }
            }
            .main-visual__contents:nth-child(2) {
                &::after {
                    transform-origin: center bottom;
                }
            }
        }
        &__contents {
            height: 100%;
            &--1 {
                &__img {
                    margin: 0;
                }
            }
            &--3 {
                &__inner {
                    .main-visual__caption {
                        right: unset;
                        left: 8px;
                        bottom: 60px;
                    }
                }
            }
            &--3 {
                &__inner {
                    justify-content: flex-start;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
@keyframes scaleY {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: scaleY(1);
    }
}
@keyframes scaleX {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: scaleX(0);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: scaleX(1);
    }
}
</style>
