<template>
    <v-row align="start" class="contact-card-group">
        <v-col v-for="(item, index) in contact" :key="index" cols="6" md="3" class="contact-card-col">
            <div :data-aos="item.aos" data-aos-duration="700" :data-aos-delay="item.aosDelay" class="contact-card-wrap">
                <p class="contact-card__caption d-none d-md-block font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase" >Lawfirm Taesung</p>
                <div class="contact-card">
                    <div class="contact-card__thumb-wrap">
                        <v-card :href="item.link" target="_blank" rounded="sm" class="contact-card__thumb v-card--none-active-bg transparent">
                            <v-img :src="item.image" max-width="342" :aspect-ratio="342 / 512" class="contact-card__thumb__thumb w-100"/>
                            <div class="contact-card__active pa-16px">
                                <txt class="primary--text line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px">0{{index+1}}</txt>
                                <tit class="white--text tit--sm">{{item.title}}</tit>
                                <txt class="white--text my-16px my-md-32px">
                                    <span v-html="item.location"></span>
                                </txt>
                                <!-- <icon-arrow-primary size="x-large" class="white--text"/> -->
                            </div>
                        </v-card>
                    </div>
                    <div class="contact-card__contents pt-16px pt-md-32px d-none d-lg-block"> 
                        <txt class="txt--light line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px">0{{index+1}}</txt>
                        <tit class="white--text tit--sm">{{item.title}}</tit>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
// import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
export default {
    components: {
        // IconArrowPrimary,
        Tit,
        Txt,
    },
    data: () => {
        return {            
            contact : [
                {
                    title : "서울주사무소 (건설)",
                    location : "서울 서초구 강남대로 202, <br class='d-none d-xl-block' />모산빌딩 3층",
                    link : "https://goo.gl/maps/WsqFk6mTHS9iMxnWA",
                    image: "/images/main/contact-img.jpg",
                    aos: "fade-up",
                    aosDelay: 100,
                },
                {
                    title : "서울분사무소 (이혼)",
                    location : "서울 서초구 강남대로 196, <br class='d-none d-xl-block' />구민빌딩 2층",
                    link : "https://goo.gl/maps/VUhH2iNyGfukpJP39",
                    image: "/images/main/contact-img2.jpg",
                    aos: "fade-down",
                    aosDelay: 200,
                },
                {
                    title : "인천분사무소",
                    location : "인천 미추홀구 경원대로 892, <br class='d-none d-xl-block' />대동빌딩 1층, 5층",
                    link : "https://goo.gl/maps/M5sStAuYzPDBtCZx9",
                    image: "/images/main/contact-img3.jpg",
                    aos: "fade-up",
                    aosDelay: 300,
                },
                {
                    title : "광주분사무소",
                    location : "광주 동구 지산로 70, <br class='d-none d-xl-block' />동산빌딩 2층",
                    link : "https://goo.gl/maps/UoYnT7Ne8TuZmkVR8",
                    image: "/images/main/contact-img4.jpg",
                    aos: "fade-down",
                    aosDelay: 400,
                },
            ]
        };
    },
}
</script>

<style lang="scss" scoped>
.contact-card{
    height: 100%;
    &-wrap{
        height: 100%;
        position: relative;
    }
    &__thumb{
        overflow: hidden;     
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: calc(100% - 12px);
            height:  calc(100% - 12px);
            top: 6px;
            left: 6px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom-right-radius: 14px;
        }
    }
    &__active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .contact-card{
        &-wrap{
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                height: 600px;
                background-color: rgba(255, 255, 255, 0.2);
                right: 0;
            }
        }
        &__caption{
            position: absolute;
            right: 10px;
        }
        &__thumb{
            &::after{
                content: "";
                width: calc(100% - 20px);
                height:  calc(100% - 20px);
                top: 10px;
                left: 10px;
                border-bottom-right-radius: 20px;
            }
        }
    }
    .contact-card-group{
        .contact-card-col:nth-child(2n -1){
            .contact-card{
                &-wrap{
                    &::before{
                        top: 0;
                        transform: translateY(-100%);
                    }
                }
                &__caption{
                    top: -20px;
                    right: 10px;
                    transform: translateY(-100%);
                }
            }
        }
        .contact-card-col:nth-child(2n){
            margin-top: 40px;
            .contact-card{
                &-wrap{
                    &::before{
                        bottom: 150px;
                        transform: translateY(100%);
                    }
                }
                &__caption{
                    bottom: -2px;
                    transform: translateY(100%);
                }
            }
        }
    }
}
@media (min-width:1024px){
    .contact-card{
        &__contents{
            position: absolute;
            left: 0;
            bottom: 0;
            opacity: 1;
            visibility: visible;
            transition: 0.25s ease-out;
        }
        &__thumb{
            &-wrap{
                padding-bottom: 92px;
                transition: 0.25s ease-out;
            }    
            &__thumb{
                padding-bottom: 0;
                transition: 0.25s ease-out;
            }        
            &::after{
                border-bottom-right-radius: 36px;
                transform: scaleY(0);
                transition: 0.25s ease-out;
            }
        }
        &__active{
            opacity: 0;
            visibility: hidden;
            transition: 0.25s ease-out;
            >*{
                transform: scale(1.4);
                opacity: 0;
                visibility: hidden;
                transition: 0.25s ease-out;
            }
        }
        &:hover{
            .contact-card__contents{
                opacity: 0;
                visibility: hidden;                
            }
            .contact-card__thumb__thumb{
                padding-bottom: 92px;        
            }
            .contact-card__thumb-wrap{
                padding-bottom: 0;
            }
            .contact-card__thumb::after{
                transform: scaleY(1);
                transition: 0.5s 0.6s ease-out;              
            }
            .contact-card__active{
                opacity: 1;
                visibility: visible;   
                >*{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;  
                    transition: 0.5s ease-out;
                    &:nth-child(1){
                        transition-delay: 0.25s;
                    }
                    &:nth-child(2){
                        transition-delay: 0.35s;
                    }
                    &:nth-child(3){
                        transition-delay: 0.45s;
                    }
                    // &:nth-child(4){
                    //     transition-delay: 0.55s;
                    // }
                }      
            }
        }
    }
    .contact-card-group{
        .contact-card-col:nth-child(2n){
            margin-top: 80px;
        }
    }
}
@media (min-width:1200px){
}

</style>